@media (min-width: 768px) {
  .content {
      margin-left: 260px;
  }
}

main {
  display: block;
  margin: 0;
}

.content {
  overflow: visible;
  padding: 0 1rem;
  transition: all .3s;
}

.user-avatar {
  height: 2.5rem;
  width: 2.5rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 50%;
}

.user-dropdown.dropdown-menu.show {
  right: 0 !important;
  left: auto !important;
}

.dropdown-menu .dropdown-item {
  color: #66799e;
  transition: all .2s ease;
}

.logout-ico {
  color: red;
}

@media (min-width: 768px) {
  .sidebar {
    width: 100%;
    max-width: 260px;
  }
}

.sidebar {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  transition: max-width .3s;
  background: linear-gradient(#141e30, #243b55) !important;
}

.na-b0-logo {
  width: 90%;
  height: auto;
}

.sidebar .nav-item.active>.nav-link {
  color: #eaedf2;
  background-color: #2e3650;
  border: 0.0625rem solid #4c5680;
}

.sidebar .nav-item .nav-link {
  color: #eaedf2;
  border: 0.0625rem solid #262b40;
}

.sidebar .nav-item {
  border: 0.0625rem solid transparent;
  margin-bottom: 0.2rem;
}

.sidebar .nav-link {
  font-size: 1rem;
  vertical-align: middle;
  padding: 0.55rem 0.75rem;
  border-radius: 0.5rem;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.sidebar-inner .accordion {
  padding-top: 0.25rem;
}

.sidebar .nav {
  white-space: nowrap;
}

.sidebar .nav-link .sidebar-icon {
  margin-right: 0.5rem;
  color: #fff;
}

.nav-link span {
  position: relative;
}

.sidebar .badge, .sidebar .link-arrow, .sidebar .notification-count, .sidebar .sidebar-text {
  opacity: 1;
  transition: opacity .3s;
}

.accordion-item:last-of-type .accordion-button.collapsed, .accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 0.0625rem;
}

.sidebar-inner .accordion-collapse {
  border: 0;
}

.sidebar-inner .accordion-body {
  padding: 0.5rem 0 0;
  background: linear-gradient(#141e30, #243b55);
}

.sidebar .multi-level .nav-link {
  padding-left: 45px;
}

a.d-flex.justify-content-between.align-items-center.accordion-button.collapsed.nav-link, a.d-flex.justify-content-between.align-items-center.accordion-button.nav-link {
  background: linear-gradient(#141e30, #243b55);
}

.accordion-item:last-of-type {
  border: none !important;
}

.bg-card-shadow {
  width: 100% !important;
  box-shadow: 2px 13px 54px rgb(198 220 249 / 39%) !important;
  background: #fff 0 0 no-repeat padding-box !important;
  border-radius: 25px !important;
  border: none !important;
}

.bg-card-shadow .card {
  border: none !important;
}

.export-to-csv-button {
  text-decoration: none;
  border: 1px solid #1f3249;
  color: #1f3249;
  float: right;
}

.export-to-csv-button:hover {
  text-decoration: none;
  background-color: #1f3249;
  color: #ffffff;
}

.heading-h4-home {
  /* color: linear-gradient(to right, #553c9a 45%, #ee4b2b) !important; */
  font-size: 30px;
  background: linear-gradient(to right, #553c9a 45%, #ee4b2b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* th:first-child {
  width: 60px !important;
  text-align: center;
}

td:first-child {
  width: 60px !important;
  text-align: center;
} */

th.th {
  text-transform: capitalize;
}



.card-info-icon .info-icon-text {
  visibility: hidden;
  width: 280px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size:12px;
  /* Position the tooltip */
  position: absolute;
  z-index: 5;
  bottom: 100%;
  /* left: 50%; */
  /* margin-left: -60px; */
}

.card-info-icon:hover .info-icon-text {
  visibility: visible;
}