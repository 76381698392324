.serachFilterStyle{
    max-width: 300px;
    box-shadow: none;
    border: 2px solid #ddd;
}

.serachFilterStyle:focus{
text-decoration: none;
box-shadow: none;
border: 2px solid rgb(104, 104, 104);
}
.download_btn{
    background-color: rgb(32, 105, 32);
    padding: 10px 20px;
    width: 180px;
    text-align: center;
    border-radius: 8px;
    color: #fff;
    border: 2px solid #ddd;

}

/* .dr_picker{
    max-width: 200px;
} */
.day_wise_report{
    display: flex;
    justify-content: space-evenly;
}

#customers,#table_monthly,#onDemandSpot_table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    width: 150px;
  overflow: hidden;
  }
  #onDemandSpot_table td, #onDemandSpot_table th {
    border: 1px solid #ddd;
    padding: 8px;
    width: 150px;
  overflow: hidden;
  }
#customers th:first-child{
  width: 110px;
}
#onDemandSpot_table th:first-child{
  width: 150px;
}
/* #onDemandSpot_table th:nth-child(2){
  display: none;
}
#onDemandSpot_table td:nth-child(2){
  display: none;
} */


  #table_monthly td, #table_monthly th {
    border: 1px solid #ddd;
    padding: 8px;
    width: 200px;
  overflow: hidden;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}

  #table_monthly tr:nth-child(even){background-color: #f2f2f2;}
  
  #table_monthly tr:hover {background-color: #ddd;}

  #onDemandSpot_table tr:nth-child(even){background-color: #f2f2f2;}
  
  #onDemandSpot_table tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #110235f1;
    color: white;
  }
  #onDemandSpot_table th{
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #110235f1;
    color: white;
  }
  #onDemandSpot_table th{
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #110235f1;
    color: white;
  }

  .gbf{
    margin-bottom: 1px;
  }
.gbf input{
  width: 200px;
  height: 35px;
  border-radius: 4px;
  font-size: 15px;
  padding: 8px 10px;
}
.gbf input:focus{
  border: 1px solid blueviolet;
}

.gbf input::placeholder{
  font-size: 18px !important;
}

  .pagination_btn{
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
  .pagination_btn ._buttonNext{
    padding: 4px 18px;
    color: white;
    background-color: 	blue;
    font-size: 20px;
    border: none;
    margin-right: 10px;
    border-radius: 4px;
  }
  .pagination_btn .buttonPrev{
    padding: 4px 18px;
    color: white;
    background-color: 	rgba(0, 0, 255, 0.224);
    font-size: 20px;
    border: none;
    margin-right: 10px;
    border-radius: 4px;

  }
  /* .pagination_btn button:hover{
    background-color: rgba(0, 0, 255, 0.646);
  } */
  ._onhover{
    background-color: green;
  }

  .color_plate_1{
    background-color: #432C7A !important;
    color: white;
  }
  .color_plate_2{
    background-color: #3F3B6C !important;
    color: white;
  }
  .color_plate_3{
    background-color: #624F82 !important;
    color: white;
  }
  .color_plate_4{
    background-color: #9F73AB !important;
    color: white;
  }

  .color_plate_5{
    background-color: #3F3B6C !important;
    color: white;
  };

  .moment_picker{
    width: 100px !important;
    background-color: red;
  }
  #page_limit{
    width: 50px;
    padding: 5px;
    border: 1px solid rgb(116, 116, 116);
    border-radius: 4px;
    margin-right: 7px;
  }
  #page_limit:focus{
    border: 1px solid blueviolet;
  }
 
  ._page_limit{
    display: flex;
    justify-content: space-between;
  }


  .card_bg{
    background-color: whitesmoke !important;
  }
  

.navbar_username{
  background-color: #3F3B6C;
  padding: 10px;
  color: #fff;
  border-radius: 8px;
  transition: 0.3s all ease-in-out;
}
.navbar_username:hover{
  background-color: #645fa0;

}
.navbar_username .user_icon, .profile_name{
 color: #fff;
 font-weight: 500;
}


.theme_blue_btn{
  padding: 6px 18px;
  color: white;
  background-color: 	rgba(0, 0, 255, 1);
  font-size: 18px;
  font-weight: 400;
  border: none;
  /* margin-right: 5px; */
  border-radius: 5px;

}

.navbar-toggler {
  background-color: 	rgba(0, 0, 255, 1) !important;
}
table{
  margin-top: 10px;
}
.loader_site_outer{
  position: relative;
  height: 90vh;
}
.loader_site{
  position: absolute;
  left: 50%;
  top: 50% !important;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 600px) {
  ._page_limit{
    flex-direction: column;
    }
    .gbf input{
      width: 100%;
    }
    .fw-bold{
      margin-top: 10px;
      padding-left: 5px;
    }
    .pagination_btn{
      align-items: center;
      flex-direction: column;
    }
    .button_flex{
      display: flex;
      margin-top: 10px;
    }
}

